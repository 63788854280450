import { useEffect, useState } from 'react';
import { GenericAbortSignal } from 'axios';

import { useInfiniteQuery } from '@tanstack/react-query';

import Group, { GroupOptions } from 'models/Group';
import { PaginatedItems } from 'models/Paginated';
import { BaseIndexQueryParams } from 'services/api/types';
import http from 'services/http';

export const groupsQueryKeys = {
  index: ['groups'] as const,
};

export type IndexParams = {
  name?: string;
  type?: string;
  'type.not'?: string;
  locationType?: string;
  networkClientId?: string;
  groupType?: string;
  isManager?: boolean;
  viewOnly?: boolean;
  clientId?: string;
  'id.not'?: string;
} & BaseIndexQueryParams;
export async function indexGroups(params: IndexParams, signal: GenericAbortSignal) {
  return http.get<PaginatedItems<GroupOptions>>(`groups`, { params, signal }).then((res) => {
    return {
      ...res.data,
      data: res.data.items.map((x) => new Group(x)),

      // deprecated - use `data` instead
      items: res.data.items.map((x) => new Group(x)),
    };
  });
}

export const useInfiniteGroups = (params: IndexParams) => {
  const [search, setSearch] = useState<string>();

  const options = useInfiniteQuery({
    queryKey: [groupsQueryKeys.index, { ...params, search }],
    queryFn: ({ signal, pageParam }) => indexGroups({ pageSize: 10, ...params, search, page: pageParam }, signal),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.meta.totalPages > lastPageParam ? lastPageParam + 1 : undefined;
    },
    enabled: false,
  });

  useEffect(() => {
    if (search !== undefined) options.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return { options, onDebouncedSearch: setSearch };
};
