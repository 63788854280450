import * as yup from 'yup';

import { PAYER, PHYSICIAN_GROUP, SNF_MANAGEMENT } from 'constants/locationTypes';
import Group from 'models/Group';

export function intakeFormValidation() {
  return yup.object().shape({
    name: yup.string().required('Patient name is required'),
    dateOfBirth: yup.date().required('Date of Birth is required'),
    sex: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .test('sex', 'Gender is required', (sex) => !!sex?.value),
    physicianTeam: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      // Physician is optional for payer and SNF management owners
      .when('owner', {
        is: (owner: Group) => owner.is(PAYER) || owner.is(SNF_MANAGEMENT),
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.test('physicianTeam', 'Physician is required', (obj) => Boolean(obj?.id)),
      }),
    owner: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test('owner', 'Managed By is required', (obj) => Boolean(obj?.id)),
    transferredFrom: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .when('owner', {
        is: (owner) => owner?.is(PHYSICIAN_GROUP),
        then: (schema) => schema.test('transferredFrom', 'Hospital is required', (obj) => Boolean(obj?.id)),
        otherwise: (schema) => schema.notRequired(),
      }),
    rehabFacility: yup
      .object()
      .shape({
        id: yup.string(),
        name: yup.string(),
      })
      .nullable()
      .test(
        'rehabFacility',
        (obj, context) =>
          Boolean(obj?.id) ||
          context.createError({ message: `${context.parent.locationType?.label || 'Provider'} is required` })
      ),
  });
}
